import React, { useState } from 'react';
import axios from 'axios';
import './App.css';

const VOICE_OPTIONS = [
  // Previous options
  { value: "en-US-GuyNeural", label: "Morgan Freeman-like", avatar: "🇺🇸👨" },
  { value: "en-GB-RyanNeural", label: "David Attenborough-like", avatar: "🇬🇧👨" },
  { value: "en-IN-PrabhatNeural", label: "Amitabh Bachchan-like", avatar: "🇮🇳👨" },
  
  // New options
  { value: "en-GB-SoniaNeural", label: "British English (Female)", avatar: "🇬🇧👩" },
  { value: "hi-IN-SwaraNeural", label: "Hindi (Female)", avatar: "🇮🇳👩" },
  { value: "hi-IN-MadhurNeural", label: "Hindi (Male)", avatar: "🇮🇳👨" },
  { value: "de-DE-KatjaNeural", label: "German (Female)", avatar: "🇩🇪👩" },
  { value: "de-DE-ConradNeural", label: "German (Male)", avatar: "🇩🇪👨" },
  { value: "en-US-JennyNeural", label: "US English (Female)", avatar: "🇺🇸👩" },
  { value: "en-IN-NeerjaNeural", label: "Indian English (Female)", avatar: "🇮🇳👩" },
  
  // Indian actors and actresses
  { value: "hi-IN-MadhurNeural", label: "Shah Rukh Khan-like", avatar: "🇮🇳🎭👨" },
  { value: "hi-IN-SwaraNeural", label: "Priyanka Chopra-like", avatar: "🇮🇳🎭👩" },
  { value: "en-IN-PrabhatNeural", label: "Amitabh Bachchan-like", avatar: "🇮🇳🎭👨" },
  { value: "en-IN-NeerjaNeural", label: "Deepika Padukone-like", avatar: "🇮🇳🎭👩" },
  { value: "hi-IN-MadhurNeural", label: "Aamir Khan-like", avatar: "🇮🇳🎭👨" },
  { value: "hi-IN-SwaraNeural", label: "Aishwarya Rai-like", avatar: "🇮🇳🎭👩" }
];

function App() {
  const [file, setFile] = useState(null);
  const [voice, setVoice] = useState(VOICE_OPTIONS[0].value);
  const [status, setStatus] = useState('');
  const [jobId, setJobId] = useState(null);
  const [progress, setProgress] = useState(0);
  const [downloadLinks, setDownloadLinks] = useState([]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleVoiceChange = (e) => {
    setVoice(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setStatus('Please select a PDF file');
      return;
    }

    const formData = new FormData();
    formData.append('pdf', file);
    formData.append('voice', voice);

    try {
      setStatus('Uploading file...');
      const response = await axios.post('/api/convert', formData);
      setJobId(response.data.jobId);
      setStatus('File uploaded. Processing...');
      checkJobStatus(response.data.jobId);
    } catch (error) {
      console.error('Upload error:', error);
      setStatus('Error during upload. Please try again.');
    }
  };

  const checkJobStatus = async (id) => {
    try {
      const response = await axios.get(`/api/job/${id}`);
      const { state, progress, downloadLinks } = response.data;

      if (state === 'completed') {
        setStatus('Conversion complete. Files ready for download.');
        setJobId(null);
        setProgress(100);
        setDownloadLinks(downloadLinks);
      } else if (state === 'failed') {
        setStatus('Conversion failed. Please try again.');
        setJobId(null);
        setProgress(0);
      } else {
        setStatus(`Processing: ${progress}%`);
        setProgress(progress);
        setTimeout(() => checkJobStatus(id), 2000);
      }
    } catch (error) {
      console.error('Job status check error:', error);
      setStatus('Error checking job status. Please try again.');
      setJobId(null);
      setProgress(0);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <svg className="App-logo" viewBox="0 0 100 100">
          <rect width="100" height="100" fill="#4CAF50" />
          <path d="M20 50 Q30 30, 40 50 T60 50 T80 50" stroke="white" strokeWidth="5" fill="none" />
          <text x="50" y="80" fontFamily="Arial, sans-serif" fontSize="14" fill="white" textAnchor="middle">Voice</text>
        </svg>
        <h1>Voice (Beta)</h1>
      </header>
      <main>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="file-upload">Select PDF:</label>
            <input
              id="file-upload"
              type="file"
              accept=".pdf"
              onChange={handleFileChange}
            />
          </div>
          <div>
            <label htmlFor="voice-select">Select Voice:</label>
            <select
              id="voice-select"
              value={voice}
              onChange={handleVoiceChange}
            >
              {VOICE_OPTIONS.map(option => (
                <option key={option.value} value={option.value}>
                  {option.avatar} {option.label}
                </option>
              ))}
            </select>
          </div>
          <button type="submit" disabled={!file || jobId}>
            Convert to Speech
          </button>
        </form>
        <p className="status">{status}</p>
        {jobId && <progress value={progress} max="100" />}
        {downloadLinks.length > 0 && (
          <div className="download-links">
            <h2>Download Links:</h2>
            <ul>
              {downloadLinks.map((link, index) => (
                <li key={index}>
                  <a href={link.downloadUrl} download>
                    {link.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </main>
    </div>
  );
}

export default App;